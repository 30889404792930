import {
  Button,
  Card,
  CardActions,
  CardContent,
  ListItem,
  ListItemText,
} from "@mui/material";

import GoogleIcon from "@mui/icons-material/Google";

type Props = {
  posId: string;
  pos: string;
  ip: string;
  ticket: string;
  createdAt: any;
  latitude: any;
  longitude: any;

  onOpenGoogleMap: (latitude: any, longitude: any) => void;
};

export const UserSelected = (props: Props) => {
  const {
    posId,
    pos,
    ip,
    ticket,
    createdAt,
    latitude,
    longitude,
    onOpenGoogleMap,
  } = props;
  return (
    <Card
      variant="outlined"
      style={{ position: "absolute", left: 0, bottom: 0, zIndex: 999 }}
    >
      <CardContent>
        <ListItem style={{ padding: 0 }}>
          <ListItemText primary="ID" secondary={posId} />
        </ListItem>

        <ListItem style={{ padding: 0 }}>
          <ListItemText primary="User" secondary={pos} />
        </ListItem>
        <ListItem style={{ padding: 0 }}>
          <ListItemText primary="IP" secondary={ip} />
        </ListItem>
        <ListItem style={{ padding: 0 }}>
          <ListItemText primary="Ticket" secondary={ticket} />
        </ListItem>
        <ListItem style={{ padding: 0 }}>
          <ListItemText primary="Ticket Created At" secondary={createdAt} />
        </ListItem>
      </CardContent>
      <CardActions>
        <Button
          startIcon={<GoogleIcon />}
          onClick={() => {
            onOpenGoogleMap(latitude, longitude);
          }}
          size="small"
        >
          Google Map
        </Button>
      </CardActions>
    </Card>
  );
};
