import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
  unclusteredPointLayerText,
} from "./layers";
import { featureCollection, feature } from "@turf/helpers";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import distance from "@turf/distance";
import Map, { Source, Layer } from "react-map-gl";
import moment from "moment";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { mapStyles } from "../../../utils/map";
import Draggable from "react-draggable";
import { ControlFilter } from "./components/control-filter";
import { UserSelected } from "./components/user-selected";
import styled from "styled-components";
const gameType = ["sports", "lottery"];

//Generate an unique rgba color by string
const generateColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
};

// generate function that returns a random string of 50 characters
const randomString = () => {
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
  let randomstring = "";
  for (let i = 0; i < 50; i++) {
    let rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars.substring(rnum, rnum + 1);
  }
  return randomstring;
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const MapPage = ({ isAuthenticated = true }) => {
  let location = useLocation();
  let history = useHistory();
  if (!isAuthenticated && location.pathname !== "/login") {
    history.push(`/login`);
  }

  const [groups, setGroups] = useState([]);
  const [agents, setAgents] = useState([]);
  const [data, setData] = useState({
    collections: [],
    toTable: [],
  });

  const [filterPos, setFilterPos] = useState("");
  const [filterTicket, setFilterTicket] = useState("");

  const [users, setUsers] = useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [busy, setBusy] = useState(false);
  const [selectedMapStyle, setSelectedMapStyle] = useState(mapStyles[0]);

  const [showTable, setShowTable] = useState(false);

  const [load, setLoad] = useState(false);

  const [groupSelected, setGroupSelected] = useState(null);
  const [agentSelected, setAgentSelected] = useState(null);
  const [open, setOpen] = useState(null);
  const [userSelected, setUserSelected] = useState(null);

  const [typeSelected, setTypeSelected] = useState(gameType[0]);
  const mapRef = useRef(null);

  const getGroups = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/getGroups`);

      if (response.ok) {
        const data = await response.json();

        if (data !== null) {
          setGroups(data);
        }
      }
    } catch (e) {
      // addToast("getGroups", { appearance: "error" });
    }
  };

  const getAgents = async (group) => {
    setBusy(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/getAgents?group=${group}`
      );
      if (response.ok) {
        const data = await response.json();

        if (data !== null) {
          setAgents(data);
        }
      }
    } catch (e) {
      // addToast("getAgents", { appearance: "error" });
    } finally {
      setBusy(false);
    }
  };

  const getLocations = async () => {
    setBusy(true);
    try {
      var params = {
        system: typeSelected,
        from: moment(dateRange[0]).format("YYYY-MM-DD"),
        to: moment(dateRange[1]).format("YYYY-MM-DD"),
        group_id: groupSelected,
        agent_id: agentSelected ? agentSelected.id : null,
      };

      Object.keys(params).forEach((key) => {
        if (params[key] === null) {
          delete params[key];
        }
      });
      var esc = encodeURIComponent;
      var query = Object.keys(params)
        .map((k) => esc(k) + "=" + esc(params[k]))
        .join("&");

      const response = await fetch(
        `${process.env.REACT_APP_API}/getPoses?${query}`,
        {
          method: "GET",
          params: {
            // luis: "hola",
          },
        }
      );
      const body = await response.json();

      setUsers(body);
    } catch (e) {
      console.log("erro");
      // addToast("getLocations", { appearance: "error" });
    } finally {
      setBusy(false);
    }
  };

  const getProximity = (tickets) => {
    //verify the proximity of the tickets by 5 km radius to the selected ticket
    let proximity = [];

    const filteredUsers = users.filter((user) => {
      return user.pos_id !== open.id;
    });

    filteredUsers.forEach((user) => {
      tickets.forEach((ticket) => {
        const distanceKM = distance(
          [ticket.longitude, ticket.latitude],
          [user.longitude, user.latitude]
        );
        if (distanceKM <= 5) {
          if (!proximity.find((item) => item.ticket === ticket.ticket)) {
            proximity.push(user);
          }
        }
      });
    });

    return proximity;
  };

  useEffect(() => {
    // if (!dateRange.includes(null)) {

    getLocations();

    // alert("dataChange");
    // }
  }, [typeSelected, dateRange, groupSelected, agentSelected]);

  useEffect(() => {
    getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line
  // const { collections, toTable } = useCallback(
  // applySortFilter({
  //   data: users,
  //   filterPos,
  //   filterTicket,
  //   mapRef,
  // }),
  //   [users, filterPos, filterTicket]
  // );

  useEffect(() => {
    if (load) {
      const { collections, toTable } = applySortFilter({
        data: users,
        filterPos,
        filterTicket,
        mapRef,
      });

      setData({ collections, toTable });
    }
  }, [users, filterPos, filterTicket, load]);

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <Map
        initialViewState={{
          latitude: 40.67,
          longitude: -103.59,
          zoom: 3,
          bearing: 0,
          pitch: 0,
        }}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX || ""}
        mapStyle={mapStyles[0].url}
        style={{ width: "100%", height: "100%" }}
        interactiveLayerIds={["unclustered-point"]}
        onClick={(event) => {
          const e = event && event.features[0];
          if (e && e.properties) {
            setUserSelected(e.properties);
          }
        }}
        onLoad={() => {
          const doc = document.getElementsByClassName(
            "mapboxgl-ctrl-bottom-right"
          );
          doc[0].remove();

          console.log("test");
          setLoad(true);
        }}
        ref={mapRef}
      ></Map>

      <ControlFilter
        groups={groups}
        agents={agents}
        filterPos={filterPos}
        gameTypeSelected={typeSelected}
        onGameTypeChange={(newValue) => {
          setTypeSelected(newValue);
        }}
        onFilterTicketChange={(newValue) => {
          setFilterTicket(newValue);
        }}
        onAgentChange={(newValue) => {
          if (newValue) {
            setAgentSelected(newValue);
          } else {
            setAgentSelected(null);
          }
        }}
        onGroupChange={(newValue) => {
          if (newValue) {
            setGroupSelected(newValue.id);
            getAgents(newValue.id);
          } else {
            setAgentSelected(null);
            setGroupSelected(null);
            setAgents([]);
          }
        }}
        onFilterPosChange={(newValue) => {
          setFilterPos(newValue);
        }}
        date={dateRange}
        onDateChange={(newValue) => {
          setDateRange(newValue);
        }}
      />

      <div style={{ position: "absolute", zIndex: 999, top: 20, right: 20 }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            setShowTable(true);
          }}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none" }), background: "#FFF" }}
        >
          <MenuIcon />
        </IconButton>
      </div>

      {/* <ControlStyle
        onMapStyleChange={(newStyle) => {
          mapRef.current.getMap().setStyle(newStyle.url);

          setBusy(true);
          setTimeout(() => {
            // mapRef.current.getMap().triggerRepaint();
            const { collections, toTable } = applySortFilter({
              data: users,
              filterPos,
              filterTicket,
              mapRef,
            });
            setBusy(false);
          }, 1000);

          // setSelectedMapStyle(newStyle);
        }}
      /> */}

      {userSelected && (
        <UserSelected
          {...userSelected}
          onOpenGoogleMap={() => {
            window
              .open(
                `https://www.google.com/maps/?q=${userSelected.latitude},${userSelected.longitude}`,
                "_blank"
              )
              .focus();
          }}
        />
      )}

      {open && (
        <Dialog
          hideBackdrop
          open={true}
          onClose={() => {
            setOpen(null);
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "700px", // Set your width here
              },
            },
          }}
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {open.pos} -- {open.id}: Tickets List
          </DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Ticket #</TableCell>
                    <TableCell align="right">Created At</TableCell>
                    <TableCell align="right">IP</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {open.tickets.map((row) => (
                    <TableRow
                      key={row.ticket}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.ticket}
                      </TableCell>
                      <TableCell align="right">{row.datetime}</TableCell>
                      <TableCell align="right">{row.ip}</TableCell>
                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          onClick={() => {
                            mapRef.current.getMap().flyTo({
                              center: [row.longitude, row.latitude],
                              zoom: 15,
                            });
                          }}
                        >
                          See on map
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div>
              Proximity list
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Ticket #</TableCell>
                      <TableCell align="right">Created At</TableCell>
                      <TableCell align="right">IP</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getProximity(open.tickets).map((row) => (
                      <TableRow
                        key={row.ticket}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.pos} - {row.pos_id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.ticket}
                        </TableCell>
                        <TableCell align="right">{row.ip}</TableCell>
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            onClick={() => {
                              mapRef.current.getMap().flyTo({
                                center: [row.longitude, row.latitude],
                                zoom: 15,
                              });
                            }}
                          >
                            See on map
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(null);
              }}
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Drawer
        anchor={"right"}
        variant="persistent"
        open={showTable}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        onClose={() => {
          setShowTable(false);
        }}
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 300,
            boxSizing: "border-box",
          },
        }}
      >
        <DrawerHeader>
          <IconButton
            onClick={() => {
              setShowTable(false);
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <div
          style={{
            // background: "#FFF",
            // bottom: 0,
            overflow: "auto",
            height: "100%",
            // right: 0,
          }}
        >
          <div style={{ overflow: "auto", minHeight: "50vh" }}>
            <table
              style={{
                width: "100%",
                marginBottom: "1rem",
                overflow: "auto",
                color: "#212529",
                verticalAlign: "top",
                borderColor: "#dee2e6",
                captionSide: "bottom",
                borderCollapse: "collapse",
              }}
            >
              <thead
                style={{
                  borderColor: "inherit",
                  borderStyle: "solid",
                  borderWidth: 0,
                  verticalAlign: "bottom",
                }}
              >
                <tr
                  style={{
                    borderColor: "inherit",
                    borderStyle: "solid",
                    borderWidth: "0",
                  }}
                >
                  <th scope="col">Color</th>
                  <th scope="col">POS</th>
                  {/* <th scope="col">Owner</th> */}
                  <th scope="col">Quantity</th>
                </tr>
              </thead>
              <tbody>
                {data.toTable.map((data) => (
                  <tr key={data.pos}>
                    <td>
                      <div style={{ background: data.color }}>{data.color}</div>
                    </td>
                    <th scope="row">
                      <a
                        href="#"
                        onClick={() => {
                          setOpen(data);
                          // setOpen(true);
                        }}
                      >
                        {data.pos}
                      </a>
                    </th>
                    {/* <td>
                    <div>{data.owner}</div>
                  </td> */}
                    <td>{data.tickets.length}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Drawer>

      {busy && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
};

function applySortFilter({ data, filterPos, filterTicket, mapRef }) {
  if (data === null) {
    if (mapRef.current) {
    }
    return { data: [], collections: [], toTable: [] };
  }

  if (filterPos && filterPos.length > 0) {
    data = data.filter((user) =>
      user.pos.toUpperCase().includes(filterPos.toUpperCase())
    );
  }

  if (filterTicket && filterTicket.length > 0) {
    data = data.filter((user) =>
      user.ticket.toUpperCase().includes(filterTicket.toUpperCase())
    );
  }
  const features = [];
  const toTable = [];

  if (data) {
    data.forEach((res) => {
      const color = generateColor(res.pos + randomString());

      const index = toTable.findIndex((t) => t.pos === res.pos);
      if (index >= 0) {
        // toTable[index].quantity++;

        toTable[index].tickets.push(res);
      } else {
        toTable.push({
          id: res.pos_id,
          pos: res.pos,
          // owner: res.owner,
          // quantity: 1,
          color: color,
          tickets: [res],
        });
      }
      const object = {
        ip: res.ip,
        pos: res.pos,
        posId: res.pos_id,
        longitude: res.longitude,
        latitude: res.latitude,
        ticket: res.ticket,
        createdAt: res.datetime,
        color: index >= 0 ? toTable[index].color : color,
      };

      features.push(
        feature(
          { type: "Point", coordinates: [res.longitude, res.latitude] },
          object
        )
      );
    });

    // setDataTransformer(toTable);
    // setCollections(featureCollection(features));
  }

  if (mapRef.current) {
    if (mapRef.current.getMap().getLayer("clusters"))
      mapRef.current.getMap().removeLayer("clusters");

    if (mapRef.current.getMap().getLayer("cluster-count"))
      mapRef.current.getMap().removeLayer("cluster-count");

    if (mapRef.current.getMap().getLayer("unclustered-point"))
      mapRef.current.getMap().removeLayer("unclustered-point");

    if (mapRef.current.getMap().getLayer("unclustered-point-text"))
      mapRef.current.getMap().removeLayer("unclustered-point-text");

    if (mapRef.current.getMap().getSource("pos"))
      mapRef.current.getMap().removeSource("pos");

    mapRef.current.getMap().addSource("pos", {
      type: "geojson",
      data: featureCollection(features),
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    });
    mapRef.current.getMap().addLayer(clusterLayer);
    mapRef.current.getMap().addLayer(clusterCountLayer);
    mapRef.current.getMap().addLayer(unclusteredPointLayer);
    mapRef.current.getMap().addLayer(unclusteredPointLayerText);
  }

  return { data, collections: featureCollection(features), toTable };
}
