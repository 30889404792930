import React from "react";
import {
  Autocomplete,
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import "rsuite/dist/rsuite.min.css";

import { DateRangePicker } from "rsuite";

type Props = {
  groups: any;
  agents: any;
  gameTypeSelected: any;
  agentSelected: any;
  groupSelected: any;

  filterPos: string;
  onFilterPosChange: (pos: string) => void;

  filterTicket: string;
  onFilterTicketChange: (ticket: string) => void;

  onGroupChange: (group: any) => void;
  onAgentChange: (agent: any) => void;
  onGameTypeChange: (gameType: any) => void;

  date: Date[];
  onDateChange: (date: Date[]) => void;
  // fromDate: Date;
  // onFromDateChange: (date: Date) => void;
  // toDate: Date;
  // onToDateChange: (date: Date) => void;
};
const gameType = ["sports", "lottery"];
export const ControlFilter = (props: Props) => {
  const {
    groups,
    agents,
    groupSelected,
    agentSelected,
    onGroupChange,
    onAgentChange,
    date,
    onDateChange,
  } = props;

  return (
    <div style={{ position: "absolute", width: "100%", top: 0 }}>
      <Container>
        <Box
          sx={{
            bgcolor: "#FFF",
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
            boxShadow: 3,
          }}
        >
          <Grid container wrap={"wrap"} spacing={2} padding={2}>
            <Grid item sm={2}>
              <Autocomplete
                disablePortal
                id="group"
                value={groupSelected}
                options={groups}
                onChange={(event, newValue) => {
                  onGroupChange(newValue);
                }}
                getOptionLabel={(opt) => opt.name.toString()}
                renderOption={(props, opt, state) => (
                  <li {...props} key={opt.id}>
                    {opt.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Group" />
                )}
              />
            </Grid>
            <Grid item sm={2}>
              <Autocomplete
                disablePortal
                value={agentSelected}
                onChange={(event, newValue) => {
                  onAgentChange(newValue);
                }}
                getOptionLabel={(option) => option.name.toString()}
                id="agent"
                options={agents}
                renderInput={(params) => (
                  <TextField {...params} label="Agent" />
                )}
              />
            </Grid>
            <Grid item sm={2}>
              <div>
                <TextField
                  fullWidth
                  id="outlined-post-input"
                  label="User"
                  value={props.filterPos}
                  type="search"
                  onChange={(event) => {
                    props.onFilterPosChange(event.target.value);
                  }}
                />
              </div>
            </Grid>
            <Grid item sm={2}>
              <div>
                <TextField
                  fullWidth
                  value={props.filterTicket}
                  id="outlined-ticket-input"
                  label="Ticket"
                  type="search"
                  onChange={(event) => {
                    props.onFilterTicketChange(event.target.value);
                  }}
                />
              </div>
            </Grid>
            <Grid item sm={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Sistem</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.gameTypeSelected}
                  label="Sistem"
                  onChange={(event) => {
                    props.onGameTypeChange(event.target.value);
                  }}
                >
                  {gameType.map((t) => (
                    <MenuItem key={t} value={t}>
                      {t}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={2}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(event) => {
                        if (event.target.checked) {
                          // onMapStyleChange(mapStyles[2]);
                        } else {
                          // onMapStyleChange(mapStyles[0]);
                        }
                      }}
                    />
                  }
                  labelPlacement="top"
                  label="Active Real"
                />
              </FormGroup>
              {/* <ControlStyle
                onMapStyleChange={(newStyle) => {
                  // mapRef.current.getMap().setStyle(newStyle.url);
                  // setBusy(true);
                  // setTimeout(() => {
                  //   // mapRef.current.getMap().triggerRepaint();
                  //   const { collections, toTable } = applySortFilter({
                  //     data: users,
                  //     filterPos,
                  //     filterTicket,
                  //     mapRef,
                  //   });
                  //   setBusy(false);
                  // }, 1000);
                  // setSelectedMapStyle(newStyle);
                }}
              /> */}
            </Grid>
          </Grid>
          <Grid
            container
            wrap={"wrap"}
            spacing={2}
            paddingX={2}
            paddingBottom={2}
          >
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
            <Grid item sm={12}>
              <DateRangePicker
                block
                size="lg"
                value={date as any}
                onChange={(value) => {
                  onDateChange(value as any);
                }}
              />
              {/* <DatePicker
                  label="From Date"
                  value={dayjs(fromDate)}
                  sx={{ width: "100%" }}
                  onChange={(newValue) => {
                    const date = newValue?.toDate();
                    if (date) {
                      onFromDateChange(date);
                    }
                  }}
                /> */}
            </Grid>
            {/* <Grid item sm={6}>
                <DatePicker
                  sx={{ width: "100%" }}
                  label="To Date"
                  value={dayjs(toDate)}
                  minDate={dayjs(fromDate)}
                  onChange={(newValue) => {
                    const date = newValue?.toDate();
                    if (date) {
                      onToDateChange(date);
                    }
                  }}
                />
              </Grid> */}
            {/* </LocalizationProvider> */}
          </Grid>
        </Box>
      </Container>
    </div>
  );
};
