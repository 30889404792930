import React from "react";
// import { useHistory } from "react-router-dom";
import { useState } from "react";
export const LoginPage = () => {
    // let history = useHistory();
    const [form, setForm] = useState({
        username: '',
        password: ''
    });

    const handleAuthenticate = (e: any) => {
        e.preventDefault();
        if (form.username !== process.env.REACT_APP_USERNAME) {
            alert('Please enter a valid username');
            return;
        }
        if (form.password !== process.env.REACT_APP_PASSWORD) {
            alert('Please enter a valid password');
            return;
        }
        localStorage.setItem('auth', JSON.stringify(true))
        window.location.href = "/"
    }



    return (

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <form onSubmit={handleAuthenticate} >
                <div style={{ padding: '16px' }} className="container">
                    <label><b>Username</b></label>
                    <input style={{
                        width: '100%',
                        padding: '12px 20px',
                        margin: '8px 0',
                        display: 'inline-block',
                        border: '1px solid #ccc',
                        boxSizing: 'border-box',
                    }} value={form.username}
                        autoComplete="off"
                        onChange={(e) => {
                            setForm({
                                ...form,
                                username: e.target.value
                            })
                        }} type="text" placeholder="Enter Username" name="uname" required />

                    <label  ><b>Password</b></label>
                    <input style={{
                        width: '100%',
                        padding: '12px 20px',
                        margin: '8px 0',
                        display: 'inline-block',
                        border: '1px solid #ccc',
                        boxSizing: 'border-box',
                    }} onChange={(e) => {
                        setForm({
                            ...form,
                            password: e.target.value
                        })
                    }} value={form.password} type="password" placeholder="Enter Password" name="psw" required />
                    <button style={{

                        backgroundColor: '#0d6efd',
                        color: 'white',
                        padding: '14px 20px',
                        margin: '8px 0',
                        border: 'none',
                        cursor: 'pointer',
                        width: '100%',
                    }} type="submit">Login</button>
                </div>
            </form>

        </div >)
};
