import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { LoginPage } from "./pages/login";

import { MapPage } from "./pages/v2/map/index.jsx";

export const App = () => {
  const isAuthenticated =
    localStorage.getItem("auth") === "true" ? true : false;
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route exact path="/">
          <MapPage isAuthenticated={isAuthenticated} />
        </Route>
        {/* 
        <Route path="/">



        </Route> */}
      </Switch>
    </Router>
  );
};

export default App;
