export const clusterLayer = {
  id: "clusters",
  type: "circle",
  source: "pos",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": [
      "step",
      ["get", "point_count"],
      "#51bbd6",
      100,
      "#f1f075",
      750,
      "#f28cb1",
    ],
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
  },
};

export const clusterCountLayer = {
  id: "cluster-count",
  type: "symbol",
  source: "pos",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 12,
  },
};

export const unclusteredPointLayer = {
  id: "unclustered-point",
  type: "circle",
  source: "pos",
  filter: ["!", ["has", "point_count"]],
  
  paint: {
    "circle-color": ["get", "color"],

    // "circle-weight": 4,
    // "circle-width": 8,
    "circle-radius": 20,
    // "circle-stroke-width": 10,
    // "circle-stroke-color": "#000",
  },
};

export const unclusteredPointLayerBackground = {
  id: "unclustered-point-text",
  type: "background",
  source: "pos",
  filter: ["!", ["has", "point_count"]],
  
 
};

export const unclusteredPointLayerText = {
  id: "unclustered-point-text",
  type: "symbol",
  source: "pos",
  filter: ["!", ["has", "point_count"]],
  
  layout: {
    'text-field': ["get", "pos"],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
    'text-offset': [0, -2],
    // 'text-color': '#000000',
    
    // 'text-anchor': {
    //   "top": 20,
    // }
    // 'font-scale': 0.8,
    },

    paint: {
      // "circle-stroke-width": 1,
      // "circle-stroke-color": "#fff",
      // "text-halo-blur": 9,
      // 'text-halo-color': '#000000',
      "text-color": "red"
    }
};